// loadgin.vue
<template>
  <popup
    :close-on-click-overlay="false"
    v-model="show"
    style="background-color: transparent"
  >
    <div class="loadingWrap">
      <van-loading color="#F7D874" type="spinner" />
    </div>
  </popup>
</template>

<script>
import { popup } from 'vant';
export default {
  components: {
    popup
  },
  name: "loading",
  data() {
    return {
      show: false,
    };
  },
};
</script>

<style lang='less' scoped>
.van-popup {
  background: none !important;
}
</style>

