<template>
  <div id="header" :style="style">
    <img src="@/image/logo.png" alt="" class="logo">
    <div class="btns">
      <div class="btn">
        <span>{{ hideAddress(address) }}</span>
        <img src="@/image/address.png" alt="">
      </div>
      <popover v-model="showPopover" trigger="click" :actions="actions" @select="onSelect" placement="bottom-end">
        <template #reference>
          <img src="@/image/lang.png" alt="" class="lang">
        </template>
      </popover>
    </div>
  </div>
</template>

<script>
import lodash from 'lodash'
import {Popover} from 'vant'
export default {
  components: {
    Popover
  },
  data() {
    return{
      style: {},
      showPopover: false,
      // actions:[{ text: 'ภาษาไทย', type: 'thai' }, { text: 'English', type: 'en' }, { text: '繁體中文', type: 'ch' }, { text: '简体中文', type: 'zh' } ]
      actions:[{ text: 'ภาษาไทย', type: 'thai' }, { text: 'English', type: 'en' }, { text: '繁體中文', type: 'ch' }]
    }
  },
  computed: {
    address() {
      return this.$store.state.address
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll: lodash.throttle(function () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      var opacity = Math.abs(Math.floor(scrollTop)) / 100;
      this.style = {background: `rgba(0,0,0,${opacity})`}
    },10),
		hideAddress(address) {
			if (address) {
				return address.substring(0,6)+'...'+address.substring(address.length-6,address.length)
			} else {
				return ''
			}
		},
    onSelect(action) {
      this.$i18n.locale = action.type
      window.localStorage.setItem('language',action.type)
    }
  }

}
</script>

<style lang="less">
.van-tabbar,.van-tabbar-item--active{
  background: #18191d;
}
#header{
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    padding: 10px 20px 0;
    .logo{
        height: 40px;
    }
    .btns{
      display: flex;
      align-items: center;
      .btn{
        padding: 8px;
        border-radius: 14px;
        line-height: 1;
        background: #000000;
        color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 12px;
        img{
          height: 14px;
          margin-left: 10px;
        }
      }
      .lang{
        height: 30px;
				margin-left: 5px;
      }
    }
}
</style>