const english = {
  errmsg: 'Something\'s wrong~',
  up: 'Parent Address:',
  bindup: 'Bind to superior',
  confirm: 'Confirm',
  toast10: 'Initiated, waiting for block confirmation',
  toast13: 'Failed to obtain address',
  total: 'Total subscription',
  total1: 'EGA balance',
  total2: 'Dynamic rewards',
  withraw: 'extract',
  team: 'Team performance',
  team1: 'team',
  team2: 'people',
  team3: 'Direct push',
  one: 'Limited to one purchase',
  onetime: 'cycle',
  oneprice: 'limit',
  day: 'day',
  buy: 'subscription',
  sheng: 'surplus',
  income: 'Expected revenue',
  title: 'Custody',
  time: 'term',
  close: 'Early closure',
  timedown: 'Countdown',
  daoqi: 'expire',
  buyed: 'Subscribed',
  incomed: 'Gained',
  nodata: 'There are currently no hosted POOLs',
  tiyan: 'Experience',
  oneNum: 'Limit subscription',
  allIncome: 'Total revenue',
  balance: 'Available assets',
  submit: 'Confirm subscription',
  poolAll: 'Total POOL',
  shengyu: 'Remaining available for purchase',
  buynum: 'Subscription amount',
  input: 'Enter amount',
  max: 'maximum',
  title1: 'TianPOOL - Withdrawal of income',
  can: 'Extractable',
  sure: 'Confirm extraction',
  title2: 'Experience POOL - Extract Revenue',
  tab1: 'In custody',
  tab2: 'Ended',
  tian: 'day',
  shi: 'hour',
  fen: 'minute',
  miao: 'second',
  alert1: 'close',
  alert2: 'Are you sure to close in advance',
  alert3: 'Please enter the address',
  alert4: 'Please enter the quantity',
  alert5: 'Insufficient balance',
  alert6: 'Minimum investment amount',
  end: 'End',
  tabbar1: 'Management',
  tabbar2: 'Panorama',
  tabbar3: 'Investment',
  noopen: 'Not yet open. Stay tuned',
  ketiqu: 'Extractable',
  jingtai: 'Static',
  jiedian: 'Node',
  futou: 'Confirm re investment',
  shifang: 'Released',
  confirm1: 'Open',
  confirm2: 'Are you sure to restart',
  cancel: 'Cancel',
  copyLink: 'Copy invitation link',
  copySuccess: 'Successfully copied',
  reOpen: 'Reopen',
  everyDay: 'Daily limit',
  todayNum: 'Today limit',
  minnum: 'Minimum extraction',
}
export default english;
