import Vue from 'vue';
import App from './App';
import { router } from './router';
import store from './store';
import { Toast,Loading,Dialog,Progress,Icon } from 'vant';
import chinese from './lang/zh';
import english from './lang/en';
import chinese1 from './lang/ch'
import thai from './lang/thai'
import VueI18n from 'vue-i18n';
import loading from '@/components/loading.js'
import 'amfe-flexible';
Vue.prototype.$events = new Vue()
Vue.use(VueI18n)
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(loading)
Vue.use(Progress)
Vue.use(Icon)
//多语言实例
const i18n = new VueI18n({
  locale: localStorage.getItem('language') || "thai",
  globalInjection:true,
  legacy: false,
  messages: {
    thai: thai,
    en: english,
    ch: chinese1,
    zh: chinese,
  }
})
new Vue({
  router,
  store,
  i18n,
  el: '#app',
  render: h => h(App)
});
